import {Box, Container, Flex, HStack, VStack} from '@styled-system/jsx';
import logo from '../../logo.png';
import {h3, muted} from '@styled-system/recipes';
import {Button} from '~/components/ui/Button';
import {Link} from '@remix-run/react';
import {Input} from '~/components/ui/Input';
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from '~/components/ui/Accordion';
import {css} from '@styled-system/css';
import InstagramIcon from '../../icons/instagram.svg?react';
import FacebookIcon from '../../icons/facebook.svg?react';
import TwitterIcon from '../../icons/twitter.svg?react';
import YouTubeIcon from '../../icons/youtube.svg?react';
import TikTokIcon from '../../icons/tik-tok.svg?react';
import LinkedInIcon from '../../icons/linkedin.svg?react';

const FooterButtonLink = ({to, children}: { to: string, children: React.ReactNode }) => {
  return <Button variant="link" asChild>
    <Link to={to}>
      {children}
    </Link>
  </Button>
}

const FooterLinks = () => {
  return <>
    <VStack alignItems="flex-start">
      <FooterButtonLink to="/promotions">Promotions</FooterButtonLink>
      <FooterButtonLink to="/winners">Winners</FooterButtonLink>
      <FooterButtonLink to="/vip-club">VIP Club</FooterButtonLink>
      <FooterButtonLink to="/about">About</FooterButtonLink>
    </VStack>
    <VStack alignItems="flex-start">
      <FooterButtonLink to="/official-rules">Official Rules</FooterButtonLink>
      <FooterButtonLink to="/refund-policy">Refund/Return Policy</FooterButtonLink>
      <FooterButtonLink to="/fulfillment-policy">Fulfillment Policy</FooterButtonLink>
      <FooterButtonLink to="/terms-of-use">Terms of Use</FooterButtonLink>
      <FooterButtonLink to="/privacy-policy">Privacy Policy</FooterButtonLink>
    </VStack>
    <VStack alignItems="flex-start">
      <FooterButtonLink to="https://c.fanathem.com">Fanathem Army</FooterButtonLink>
      <FooterButtonLink to="/contact">Contact Us</FooterButtonLink>
      <FooterButtonLink to="/faq">FAQ</FooterButtonLink>
    </VStack>
  </>
}

const SocialLinks = () => {
  return <HStack>
    <Button variant="ghost" size="icon" rounded="full" color="primary" asChild>
      <a href="https://www.youtube.com/@fan-a-them" target="_blank" rel="noreferrer">
        <YouTubeIcon/>
      </a>
    </Button>
    <Button variant="ghost" size="icon" rounded="full" color="primary" asChild>
      <a href="https://www.instagram.com/fan.a.them" target="_blank" rel="noreferrer">
        <InstagramIcon/>
      </a>
    </Button>
    <Button variant="ghost" size="icon" rounded="full" color="primary" asChild>
      <a href="https://www.facebook.com/fanathem" target="_blank" rel="noreferrer">
        <FacebookIcon/>
      </a>
    </Button>
    <Button variant="ghost" size="icon" rounded="full" color="primary" asChild>
      <a href="https://x.com/fan_a_them" target="_blank" rel="noreferrer">
        <TwitterIcon/>
      </a>
    </Button>
    <Button variant="ghost" size="icon" rounded="full" color="primary" asChild>
      <a href="https://www.linkedin.com/company/fanathem/" target="_blank" rel="noreferrer">
        <LinkedInIcon/>
      </a>
    </Button>
    <Button variant="ghost" size="icon" rounded="full" color="primary" asChild>
      <a href="https://www.tiktok.com/@fanathem_" target="_blank" rel="noreferrer">
        <TikTokIcon/>
      </a>
    </Button>
  </HStack>
}

const Footer = () => {
  return <Box
    p={4}
    bg="black"
    color="white"
    minH="200px"
  >
    <Container width="full" py={2}>
      <VStack gap={8} width="full" alignItems="stretch">
        <HStack justify="space-between" alignItems="stretch">
          <VStack justify="space-between" alignItems="flex-start" flex={1} gap={8}>
            <Box maxW="185px">
              <img src={logo} alt="" aria-hidden/>
            </Box>
            <VStack alignItems="flex-start">
              <h3 className={h3()}>
                Never Miss a Promotion
              </h3>
              <p className={muted()}>
                Enter your WhatsAPP or cell phone # to sign up for text notifications
              </p>
              <HStack width="full">
                <Input/>
                <Button>
                  Subscribe
                </Button>
              </HStack>
            </VStack>
            <Accordion type="single" collapsible w="full" hideFrom="md">
              <AccordionItem value="menu" className={css({borderTop: 'base'})}>
                <AccordionTrigger fontSize="xl">Menu</AccordionTrigger>
                <AccordionContent className={css({
                  '& > div': {
                    px: 0
                  }
                })}>
                  <VStack alignItems="flex-start">
                    <FooterLinks/>
                  </VStack>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Flex width="full" justifyContent="center" hideFrom="md">
              <SocialLinks/>
            </Flex>
          </VStack>
          <VStack flex={1} alignItems="stretch" hideBelow="md">
            <HStack justify="space-between" alignItems="flex-start">
              <FooterLinks/>
            </HStack>
            <Box alignSelf="flex-end">
              <SocialLinks/>
            </Box>
          </VStack>
        </HStack>
        <Box width="full">
          <p className={muted()}>
            Copyright © 2024, Fanathem. All rights reserved.
          </p>
        </Box>
      </VStack>
    </Container>
  </Box>
}

export default Footer;
